






































import {Component, Prop, Vue} from 'vue-property-decorator';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import BMessage from 'buefy/src/components/message/Message.vue';
import {AD_FORMAT_KEYS} from '@/model/ads/ads.model';
import ContainerLayout from '@/components/layout/ContainerLayout.vue';

@Component({
  components: {ContainerLayout, BMessage, PanelBox, HfAdBox},
})
export default class AdPage extends Vue {

  @Prop({default: ''}) title?: number;

  get titles() {
    const n = 10;
    const titles = [];
    for (let i = 0; i < n; i++) {
      titles.push(`${i}`);
    }
    return titles;
  }

  get formatKeys() {
    return [
      AD_FORMAT_KEYS.leaderboard,
      AD_FORMAT_KEYS.rectangle,
      AD_FORMAT_KEYS.skyscraper
    ];
  }

}
